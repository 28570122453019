import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";
import LastThreeRecruitment from "../components/lastThreeRecruitment";

const Recruitment = ({ data: { lastRecruitment } }) => {
	const lang = "pl";

	return (
		<Layout
			seoTitle="Rekrutacja"
			lang={lang}
			translationEN="/en/recruitment/"
			translationDE="/de/rekrutierung/"
		>
			<SmallBanner title="Rekrutacja" lang={lang} />

			<section className="recruitment-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-4">
							<h2>Praca w PalettenWerk</h2>
						</div>
						<div className="col-xl-8">
							<p>
								Dołącz do pracy u lidera produkcji palet w
								Polsce. Jesteśmy firmą o ugruntowanej pozycji na
								rynku, na którym działamy od ponad 30 lat.
								Oferujemy stabilne zatrudnienie na podstawie
								umowy o pracę, nieustannie inwestujemy w rozwój
								firmy oraz pracowników. Zależnie od sprawowanego
								stanowiska gwarantujemy zapewnienie niezbędnego
								sprzętu, wyposażenia BHP, a także szkolenia
								stanowiskowe.
							</p>
							<img
								className="img-fluid"
								src={require("../assets/img/recruitment-image-1.jpg")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="recruitment-middle">
				<div className="container">
					<div className="row align-items-end">
						<div className="col-md-4">
							<img
								className="img-fluid"
								src={require("../assets/img/recruitment-image-2.jpg")}
								alt=""
							/>
						</div>
						<div className="col-md-8">
							<p>
								Nasze oddziały znajdują się w trzech
								lokalizacjach. W Jordanowie (woj. małopolskie)
								oraz Sędziszowie (woj. świętokrzyskie)
								produkujemy palety drewniane, natomiast w
								Krzeszowicach pod Krakowem, zajmujemy
							    się projektowaniem i produkcją prefabrykowanych
								domów z drewna. Sprawdź nasze aktualne oferty
								pracy widniejące poniżej i dołącz do
								naszego zespołu już dziś!
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="recruitment-grid">
				<div className="container">
					<h2>Obecnie szukamy:</h2>
					<LastThreeRecruitment
						lastRecruitment={lastRecruitment}
						lang={lang}
						slug="rekrutacja"
					/>
				</div>
			</section>
		</Layout>
	);
};

export default Recruitment;

export const query = graphql`
	query {
		lastRecruitment: allWpRecruitment(
			sort: { fields: date, order: ASC }
			filter: { language: { code: { eq: PL } } }
		) {
			edges {
				node {
					uri
					id
					slug
					title
					excerpt
					language {
						code
						slug
						name
					}
					acfRecruitment {
						recruitmentLocation
						contactInfo
					}
				}
			}
		}
	}
`;
